import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

function Layout({ children, isPublic }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isLoggedIn, logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const navigation = [
    { name: 'Dashboard', href: '/', icon: 'ri-home-line' },
    { name: 'Users', href: '/admin/users', icon: 'ri-user-line' },
    { name: 'Add User', href: '/admin/add-user', icon: 'ri-user-add-line' },
    { name: 'Profile', href: '/profile', icon: 'ri-profile-line' },
  ];

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {!isPublic && (
        <>
          {/* Hamburger menu for mobile */}
          <button
            className="md:hidden fixed top-4 left-4 z-20 text-gray-500 hover:text-gray-600"
            onClick={toggleSidebar}
          >
            <FontAwesomeIcon icon={sidebarOpen ? faTimes : faBars} size="lg" />
          </button>

          {/* Sidebar */}
          <aside className={`w-64 bg-white shadow-md fixed inset-y-0 left-0 transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out z-10`}>
            <div className="p-4">
              <h2 className="text-2xl font-bold text-gray-800">QR Pay Dashboard</h2>
            </div>
            <nav className="mt-6">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`flex items-center px-4 py-2 mt-2 text-gray-600 hover:bg-gray-200 hover:text-gray-700 transition-colors duration-200 ${
                    location.pathname === item.href ? 'bg-gray-200 text-gray-700' : ''
                  }`}
                  onClick={() => setSidebarOpen(false)}
                >
                  <i className={`${item.icon} mr-3 text-lg`}></i>
                  {item.name}
                </Link>
              ))}
              {isLoggedIn ? (
                <button
                  onClick={() => {
                    handleLogout();
                    setSidebarOpen(false);
                  }}
                  className="flex items-center w-full px-4 py-2 mt-2 text-gray-600 bg-white hover:bg-gray-200 hover:text-gray-700 transition-colors duration-200"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-3 text-lg" />
                  Logout
                </button>
              ) : (
                <Link
                  to="/login"
                  className="flex items-center px-4 py-2 mt-2 text-gray-600 hover:bg-gray-200 hover:text-gray-700 transition-colors duration-200"
                  onClick={() => setSidebarOpen(false)}
                >
                  <FontAwesomeIcon icon={faSignInAlt} className="mr-3 text-lg" />
                  Login
                </Link>
              )}
            </nav>
          </aside>
        </>
      )}

      {/* Main content */}
      <div className={`flex-1 flex flex-col overflow-hidden ${isPublic ? 'w-full' : ''}`}>
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4">
          {children}
        </main>
      </div>
    </div>
  );
}

export default Layout;
