import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faLock } from '@fortawesome/free-solid-svg-icons';
import './Profile.css';

function Profile() {
  const [newAdmin, setNewAdmin] = useState({ username: '', password: '' });
  const [changePassword, setChangePassword] = useState({ currentPassword: '', newPassword: '' });

  const handleNewAdminSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/create-admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newAdmin),
      });
      if (response.ok) {
        alert('New admin user created successfully');
        setNewAdmin({ username: '', password: '' });
      } else {
        alert('Error creating new admin user');
      }
    } catch (error) {
      console.error('Error creating new admin:', error);
      alert('Error creating new admin user');
    }
  };

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(changePassword),
      });
      if (response.ok) {
        alert('Password changed successfully');
        setChangePassword({ currentPassword: '', newPassword: '' });
      } else {
        alert('Error changing password');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      alert('Error changing password');
    }
  };

  return (
    <div className="profile-container">
      <h2>Profile</h2>
      <div className="profile-section">
        <h3><FontAwesomeIcon icon={faUserPlus} /> Create New Admin User</h3>
        <form onSubmit={handleNewAdminSubmit}>
          <input
            type="text"
            placeholder="Username"
            value={newAdmin.username}
            onChange={(e) => setNewAdmin({ ...newAdmin, username: e.target.value })}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={newAdmin.password}
            onChange={(e) => setNewAdmin({ ...newAdmin, password: e.target.value })}
            required
          />
          <button type="submit">Create Admin</button>
        </form>
      </div>
      <div className="profile-section">
        <h3><FontAwesomeIcon icon={faLock} /> Change Password</h3>
        <form onSubmit={handleChangePasswordSubmit}>
          <input
            type="password"
            placeholder="Current Password"
            value={changePassword.currentPassword}
            onChange={(e) => setChangePassword({ ...changePassword, currentPassword: e.target.value })}
            required
          />
          <input
            type="password"
            placeholder="New Password"
            value={changePassword.newPassword}
            onChange={(e) => setChangePassword({ ...changePassword, newPassword: e.target.value })}
            required
          />
          <button type="submit">Change Password</button>
        </form>
      </div>
    </div>
  );
}

export default Profile;
