import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Layout from './components/Layout';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AdminUsersList from './components/AdminUsersList';
import AdminAddUser from './components/AdminAddUser';
import Profile from './components/Profile';
import PrivateRoute from './components/PrivateRoute';
import ClientScan from './components/ClientScan';
import ThankYouPage from './components/ThankYouPage';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Layout isPublic><Login /></Layout>} />
          <Route path="/" element={<Layout><PrivateRoute><Dashboard /></PrivateRoute></Layout>} />
          <Route path="/admin/users" element={<Layout><PrivateRoute><AdminUsersList /></PrivateRoute></Layout>} />
          <Route path="/admin/add-user" element={<Layout><PrivateRoute><AdminAddUser /></PrivateRoute></Layout>} />
          <Route path="/profile" element={<Layout><PrivateRoute><Profile /></PrivateRoute></Layout>} />
          <Route path="/client/scan" element={<Layout isPublic><ClientScan /></Layout>} />
          <Route path="/thank-you" element={<Layout isPublic><ThankYouPage /></Layout>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
