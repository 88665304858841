import axios from 'axios';
import { API_BASE_URL } from '../config';

export const createPayfastPayment = async (user, amount) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/create-payfast-payment`, 
      { userId: user._id, amount }
    );
    return response.data.paymentUrl;
  } catch (error) {
    console.error('Error in createPayfastPayment:', error.response?.data || error.message || error);
    throw error;
  }
};
