import React from 'react';
import { useLocation } from 'react-router-dom';

function ThankYouPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userName = searchParams.get('userName') || 'the user';

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow-lg">
            <div className="card-body text-center">
              <h1 className="display-4 mb-4">Thank You!</h1>
              <p className="lead">
                Your payment to {userName} has been successfully processed.
              </p>
              <hr className="my-4" />
              <p>
                Thank you for using our service. Your transaction is complete.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;