import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { createPayfastPayment } from '../utils/payfast';
import { API_BASE_URL } from '../config';

function ClientScan() {
  const [user, setUser] = useState(null);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get('user_id');
    if (userId) {
      fetchUser(userId);
    } else {
      setError('No user ID provided');
      setLoading(false);
    }
  }, [location]);

  const fetchUser = async (userId) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/users/${userId}`);
      setUser(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching user:', error);
      if (error.response) {
        setError(`Error fetching user data: ${error.response.status} ${error.response.statusText}`);
      } else if (error.request) {
        setError('Network error. Please check your internet connection and try again.');
      } else {
        setError(`Error fetching user data: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user && amount) {
      try {
        const paymentUrl = await createPayfastPayment(user, parseFloat(amount));
        window.location.href = paymentUrl;
      } catch (error) {
        console.error('Error creating payment:', error.response?.data || error.message || error);
        setError('Error creating payment. Please try again. Details: ' + (error.response?.data?.error || error.message || 'Unknown error'));
      }
    } else {
      setError('User data or amount is missing');
    }
  };

  if (loading) {
    return <div className="container mt-4">Loading user data...</div>;
  }

  if (error) {
    return <div className="container mt-4 alert alert-danger">{error}</div>;
  }

  if (!user) {
    return <div className="container mt-4">No user data available</div>;
  }

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Make Payment to {user.name} {user.surname}</h2>
              <p className="text-center">Email: {user.email}</p>
              <p className="text-center">Mobile: {user.mobileNumber}</p>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="amount" className="form-label">Amount (R):</label>
                  <input
                    type="number"
                    className="form-control"
                    id="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                    step="0.01"
                    min="0.01"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">Proceed to Payment</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientScan;