import React from 'react';
import AdminUsersList from './AdminUsersList';

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
      <AdminUsersList />
    </div>
  );
}

export default Dashboard;
